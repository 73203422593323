<template>
	<div class="sect">
		<h1>entrepreneurship</h1>
		<ul>
			<li class="item" v-for="(i, id) in prog" :key="id">
				<span v-if="i.url.length > 0" class="item-header">
					<a :href="i.url" target="_blank" class="item-header">
						<companies 
							:name="i.name"
							:year="i.year">
						</companies>
					</a>
				</span>
				<span v-else class="item-header">
					<companies 
						:name="i.name"
						:year="i.year">
					</companies>
				</span>
				<p class="item-desc">{{ i.desc }}</p>
			</li>
		</ul>
	</div>
</template>

<script>
import entre from '@/json/entrepreneurship.json'
import companies from './companies'

export default {
	name: 'index',
	components: {
		companies
	},
	data() {
		return {
			prog: entre
		}
	},
	methods: {

	}
}
</script>

<style scoped>
.item {
	margin-bottom: 1em;
}

.item-desc {
	margin-top: 5px;
}
</style>