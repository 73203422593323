<template>
	<div>
		<span class="item-name">
			{{ name }},
		</span>
		<span class="item-year" v-if="year != null"> {{ year }}</span>
	</div>
</template>

<script>
export default {
	name: "Companies",
	data() {
		return {
		}
	},
	props: {
		name: String,
		year: Number
	}
}
</script>

<style scoped>
.item-name {
	display: inline;
}
</style>