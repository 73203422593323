<template>
    <div id="news" class="sect">
        <h1>news</h1>
        <ul>
            <li class="item" v-for="(n, id) in news" :key="id">
                <a v-if="n.url" :href="n.url">
                    <p class="item-desc">{{ n.date }} - {{ n.desc }}</p>
                </a>
                <p class="item-desc" v-else>{{ n.date }} - {{ n.desc }}</p>
            </li>
        </ul>
    </div>
</template>

<script>
import updates from '@/json/news.json'

export default {
    name: 'projects',
    data() {
        return {
            news: updates
        }
    },
    methods: {
    }
}
</script>

<style scoped>
</style>
