import { render, staticRenderFns } from "./entrepreneurship.vue?vue&type=template&id=203ae406&scoped=true&"
import script from "./entrepreneurship.vue?vue&type=script&lang=js&"
export * from "./entrepreneurship.vue?vue&type=script&lang=js&"
import style0 from "./entrepreneurship.vue?vue&type=style&index=0&id=203ae406&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "203ae406",
  null
  
)

export default component.exports